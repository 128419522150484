<template>
  <div class="overflow-y-auto">
    <div class="py-4 mb-16 max-w-12xl mx-auto">
      <h1 class="text-2xl text-black font-semibold px-4">Trending</h1>

      <!-- SLIDE -->

      <div class="">
        <Splide class="p-8" :slides="slides" :options="options">
          <SplideSlide v-for="(slide, i) in slides" :key="i">
            <div class="inline-block">
              <div>
                <img :src="`${api_host}/uploads/${slide.image}`" />
              </div>

              <div class="mt-4 flex items-center justify-center">
                <a
                  v-if="slide.buttonUrl"
                  target="_blank"
                  :href="slide.buttonUrl"
                  class="px-4 font-semibold inline-block mx-auto rounded py-1"
                  style="background-color: #d4b376"
                >
                  Explore
                </a>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>

      <!-- SLIDE // -->
    </div>

    <!-- EVENTS -->

    <div style="border-top: #d2ae6d 4px solid; margin-bottom: -70px"></div>

    <div class="flex flex-col container mx-auto m-auto p-auto mb-0 relative">
      <div
        v-if="events.length > 3"
        @click="scrollRight"
        class="scrollbutton scrollbutton--right hidden md:block"
      >
        <img
          class="w-4"
          src="https://experglobal.com/arrow-right2.svg"
          alt=""
        />
      </div>
      <div
        v-if="events.length > 3"
        @click="scrollLeft"
        class="scrollbutton scrollbutton--left hidden md:block"
      >
        <img class="w-4" src="https://experglobal.com/arrow-left2.svg" alt="" />
      </div>
      <div
        class="flex overflow-x-scroll pb-10 hide-scroll-bar"
        style="direction: rtl"
        ref="eventArea"
      >
        <div class="flex flex-nowrap mx-auto">
          <div
            v-for="(event, i) in events"
            :key="event._id"
            class="event-card inline-block px-8 hover:shadow-lg cursor-pointer"
            :class="[i == selectedEvent ? 'active-event-box' : '']"
            @click="selectedEvent = i"
          >
            <div
              class="
                w-56
                h-auto
                max-w-xs
                overflow-hidden
                p-2
                text-white text-left
              "
            >
              <h1
                style="color: #d2ae6d"
                class="font-bold pt-5 pb-5 font-firasans"
              >
                {{ event.monthFormatted }}
              </h1>
            </div>
            <div
              :class="[i == selectedEvent ? 'active-event' : '']"
              class="
                w-56
                h-auto
                max-w-xs
                overflow-hidden
                p-2
                text-white text-left
              "
            >
              <img
                :src="`https://cms.experglobal.com/uploads/${event.featuredImage}`"
              />
              <p class="pt-4 text-black text-sm font-firasans">
                <b> {{ event.name }} </b>
              </p>
              <p class="text-black text-sm font-palanquin">
                {{ event.description }}
              </p>
            </div>
          </div>

          <div v-if="moreDataExists" class="relative h-full">
            <button
              @click="handleLoadMoreClick(nextSkip)"
              class="
                customclass
                px-6
                flex
                justify-center
                rounded-full
                items-center
                ml-10
                w-20
                h-20
                bg-gray-100
                text-sm
              "
            >
              <svg
                class=""
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="events.length"
      class="max-w-5xl flex flex-col container mx-auto m-auto p-auto"
    >
      <div
        class="
          sm:grid sm:h-full
          h-full
          lg:mx-auto
          mx-auto
          px-auto
          sm:grid-flow-row sm:gap-4 sm:grid-cols-3
          lg:mx-auto
        "
      >
        <div
          v-for="(photo, i) in currentEvent.photos"
          :key="i"
          class="flex flex-col px-4 py-4 rounded"
        >
          <img
            v-if="photo.photo"
            :src="`https://cms.experglobal.com/uploads/${photo.photo}`"
          />
          <p v-show="photo.caption" class="text-sm p-2 text-center font-bold">
            {{ photo.caption }}
          </p>
        </div>
      </div>
    </div>

    <!-- EVETNS // -->
  </div>
</template>

<script>
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { Splide, SplideSlide } from "@splidejs/vue-splide";

import axios from "axios";
export default {
  name: "Trending",

  data() {
    return {
      api_host: process.env.VUE_APP_API_HOST,
      options: {
        clones: 1,
        type: "loop",
        autoWidth: true,
        width: "100%",
        gap: "0.7rem",
        autoplay: true,
      },
      slides: [],
      subscribe: {
        showSuccessMessage: false,
        showFailureMessage: false,
        showEmailErrorMessage: false,
        email: "",
      },
      newsletters: [],
      events: [],
      selectedEvent: 3,
      moreDataExists: false,
      nextSkip: 0,
      moreNewslettersExist: false,
      nextNewsletterSkip: 0,
    };
  },
  mounted() {
    this.getTrendingSlides();
    this.getEvents(0);
    this.getNewsletters();
  },
  computed: {
    currentEvent() {
      return this.events[this.selectedEvent];
    },
  },
  components: {
    Splide,
    SplideSlide,
  },
  methods: {
    async getTrendingSlides() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_HOST}/api/v1/trendingslides`
        );

        if (res.status === 200) {
          this.slides = res.data.slides;
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async loadMoreNewsletters() {
      try {
        const res = await axios.get(
          "https://cms.experglobal.com/api/v1/newsletter?previousSkip=" +
            this.nextNewsletterSkip
        );
        if (res.status == 200) {
          this.newsletters.push(...res.data.newsletters);
          this.nextNewsletterSkip = res.data.nextSkip;
          this.moreNewslettersExist = res.data.moreNewslettersExist;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getNewsletters() {
      try {
        const res = await axios.get(
          "https://cms.experglobal.com/api/v1/newsletter?previousSkip=0"
        );
        if (res.status == 200) {
          this.newsletters = res.data.newsletters;
          this.moreNewslettersExist = res.data.moreNewslettersExist;
          this.nextNewsletterSkip = res.data.nextSkip;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async subscribeNewsletter() {
      try {
        const res = await axios.post(
          "https://cms.experglobal.com/api/v1/subscribe",
          {
            email: this.subscribe.email,
          }
        );
        if (res.status == 200) {
          this.subscribe.showSuccessMessage = true;
          setTimeout(() => {
            this.subscribe.showSuccessMessage = false;
          }, 5000);
          this.subscribe.email = "";
        } else if (res.status == 400) {
          this.subscribe.showEmailErrorMessage = true;
          setTimeout(() => {
            this.subscribe.showEmailErrorMessage = false;
          }, 5000);
        } else {
          this.subscribe.showFailureMessage = true;
          setTimeout(() => {
            this.subscribe.showFailureMessage = false;
          }, 5000);
        }
      } catch (e) {
        if (e.response.data.error.code == 400) {
          this.subscribe.showEmailErrorMessage = true;
          setTimeout(() => {
            this.subscribe.showEmailErrorMessage = false;
          }, 5000);
        }
      }
    },
    async handleLoadMoreClick(skip) {
      try {
        const res = await axios.get(
          "https://cms.experglobal.com/api/v1/events?previousSkip=" + skip
        );
        if (res.status == 200) {
          this.events.push(...res.data.events);
          this.nextSkip = res.data.nextSkip;
          this.moreDataExists = res.data.moreDataExists;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEvents(skip) {
      try {
        const res = await axios.get(
          "https://cms.experglobal.com/api/v1/events?previousSkip=" + skip
        );
        if (res.status === 200) {
          this.events = res.data.events;
          this.moreDataExists = res.data.moreDataExists;
          this.nextSkip = res.data.nextSkip;
          return res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    // handleEventsScroll($e) {
    //   if (this.scrollable == false) {
    //     $e.preventDefault();
    //     this.$refs.eventArea.scrollLeft += $e.wheelDeltaY;
    //   }
    //   // $e.preventDefault();
    //   // console.log("e");
    //   // this.$refs.eventArea.addEventListener("wheel", function (e) {
    //   //   e.preventDefault();
    //   //   $e.target.scrollLeft += e.wheelDeltaY;
    //   //   // console.log($e.target.scrollRight);
    //   // });
    // },
    scrollLeft() {
      this.$refs.eventArea.scroll({
        left: this.$refs.eventArea.scrollLeft - 300,
        top: 0,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.eventArea.scroll({
        left: this.$refs.eventArea.scrollLeft + 300,
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>



<style scoped>
.work-sans {
  font-family: "Work Sans", sans-serif;
}
#menu-toggle:checked + #menu {
  display: block;
}
.hover\:grow {
  transition: all 0.3s;
  transform: scale(1);
}
.hover\:grow:hover {
  transform: scale(1.02);
}
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
.active-event {
  border-bottom: #d2ae6d solid 3px;
}
.active-event-box {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.customclass {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.event-card:first-child {
  margin-right: 1rem;
}
.event-card:last-child {
  margin-left: 1rem;
}
.scrollbutton {
  opacity: 0.5;
  background: gray;
  padding: 1rem;
  border-radius: 600px;
  position: absolute;
  cursor: pointer;
}
.scrollbutton--right {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.scrollbutton--left {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.snap-x {
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.snap-start {
  scroll-snap-align: start;
}
</style>